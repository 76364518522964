<template>
  <page-header-wrapper>

    <a-card :bordered="false">
      <!-- 操作 -->
      <a-tabs v-model="activeKey" @change="callBack">
          <a-tab-pane v-for="(pane, index) in panes" :key="pane.key" :tab="pane.title" >
                  <div class="table-operations">
                      <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['score:rule:add']">
                          <a-icon type="plus" />新增
                      </a-button>
                      <table-setting
                          :style="{float: 'right'}"
                          :table-size.sync="tableSize"
                          v-model="columns"
                          :refresh-loading="loading"
                          @refresh="getList()" />
                  </div>

                  <!-- 数据展示 -->
                  <a-table
                    :loading="loading"
                    rowKey="id"
                    :columns="columns"
                    :data-source="list"
                    :bordered="tableBordered"
                  >
                    <span slot="createTime" slot-scope="text, record">
                      {{ parseTime(record.createTime) }}
                    </span>
                    <span slot="operation" slot-scope="text, record">
                      <a-divider type="vertical" v-hasPermi="['score:rule:edit']" />
                      <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['score:rule:edit']">
                        <a-icon type="edit" />修改
                      </a>
                      <a-divider type="vertical" v-hasPermi="['score:rule:remove']" />
                      <a @click="handleDelete(record)" v-hasPermi="['score:rule:remove']">
                        <a-icon type="delete" />删除
                      </a>
                    </span>
                  </a-table>
          </a-tab-pane>
      </a-tabs>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList()" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getTabs} from '@/api/score/ruleCode'
import { listRule, delRule } from '@/api/score/rule'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Rule',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      // activeKey: 是默认显示哪个标签页，0就是第一个标签页，1就是第二个了
      activeKey: 0,
      // panes 标签页名数组，显示每个标签页叫什么名字
      panes: [
          {
            title: null,
            code: null,
            key: null,

          }
      ],
      list: [],
      // PannerNodeShow 该参数主要是为v-show动态的传参，使用v-show可以动态的隐藏和展示标签页
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        ruleDesc: null,
        actionCode: null,
        oper: null,
        method: null,
        score: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '规则描述',
          dataIndex: 'ruleDesc',
          ellipsis: true,
          align: 'left',
          width: '32%'
        },
        {
          title: '动作码',
          dataIndex: 'actionCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '分值',
          ellipsis: true,
          align: 'center',
          customRender: (record, index) => {
            const score = `${record.oper} ${record.score}`
            return score
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getTabs()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询积分规则列表 */
    getTabs () {
        getTabs().then(response => {
            this.panes = response.data;
            this.callBack(0);
        })
    },
    callBack(key){
        this.panes.forEach((item, index) => {
            const pane = item;
            if (item.key == key) {
                this.queryParam.type = item.code;
                this.getList();
            }
            console.log(item)
        })

    },
    /** 查询积分规则列表 */
    getList () {
      this.loading = true
      listRule(this.queryParam).then(response => {
        this.list = response.data
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        ruleDesc: undefined,
        actionCode: undefined,
        oper: undefined,
        method: undefined,
        score: undefined
      }
      this.handleQuery()
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delRule(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
  }
}
</script>
